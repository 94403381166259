.home {
  width: 100%;
  align-items: center;
  font-family: "Arial", sans-serif;
  color: #3e497a;
}

.about {
  text-align: center;
  width: 100%;
  height: calc(100vh - 70px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: #21325e;
  color: #f0f0f0;
}

.about h2 {
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-size: 80px;
  color: #e9d35b;
  height: 50px;
}

.about .prompt {
  width: 40%;
  font-size: 30px;
}
.prompt svg {
  font-size: 40px;
  margin: 5px;
  color: white;
}

.prompt p {
  line-height: 45px;
}

.prompt a {
  text-decoration: none;
  color: inherit;
}

.prompt span {
  color: #e9d35b;
  border-bottom: 3px solid #e94560;
}

.prompt span:hover {
  color: #e94560;
  border-bottom: 3px solid #e9d35b;
}

.skills {
  font-size: 20px;
  width: 100%;
  display: grid;
  align-items: center;
  justify-content: center;
  grid-template-columns: 1fr 1fr;
}

.full-about {
  padding: 0 20px;
}

.full-about p {
  text-align: start;
}
.icons-skills {
  display: flex;
  justify-content: center;
}

.icons-skills svg {
  width: 60px;
  height: 60px;
}

.contact-links {
  display: flex;
  gap: 10px;
}

.contact-links a {
  text-decoration: none;
  color: #f0f0f0;
}

.contact-links svg {
  height: 48px;
  width: 48px;
}

@media only screen and (max-width: 939px) and (min-width: 600px) {
  .about h2 {
    font-size: 45px;
  }
  .prompt {
    margin-top: 10px;
    font-size: 25px;
    width: 80%;
  }
}

@media only screen and (max-width: 600px) {
  .about h2 {
    font-size: 40px;
  }

  .prompt p {
    line-height: 25px;
    margin-top: 20px;
  }

  .about {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  .about .prompt {
    margin-top: 10px;
    font-size: 20px;
    width: 80%;
  }

  .skills {
    font-size: 15px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    grid-template-columns: 1fr 1fr;
  }
  .full-about {
    padding: 0 10px;
  }
  .icons-skills svg {
    width: 48px;
    height: 48px;
    margin-bottom: 20px;
  }

  .skills h2 {
    font-size: 30px;
    text-align: center;
  }
}
